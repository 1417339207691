.app-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family: "Inter", sans-serif;
  color: #111928;
}
.chart-selector {
  display: flex;
  justify-content: space-around;
  background-color: #f3f4f6;
  border-radius: 5px;
  padding: 5px;
  padding-top: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
}

.chart-option {
  padding: 5px 10px;
  border-radius: 5px;
  user-select: none;
  display: flex;
  flex-grow: 1;
  margin: 0 4px;
  justify-content: center;
}

.chart-container {
  width: 100%;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.chart-option:hover {
  background-color: #fff;
}

.chart-option.selected {
  background-color: #fff;
  color: #2b65ce;
}
.page-title.data-chat-mode {
  margin-right: auto;
  margin-left: 40px;
}

.header-bar {
  position: fixed;
  width: calc(100vw - 220px);
  background: #fff;
  display: flex;
  align-items: center;
  z-index: 1;
}
.header-bar h1 {
  margin-left: 54px;
}
.side-menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100vh;
  background-color: #467ded;
}

.side-menu .logo {
  margin: 28px 12px 0 28px;
  width: 124px;
}

.side-menu .menu-item-list {
  display: flex;
  flex-direction: column;
}

.normal-button {
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  color: #4b5563;
  cursor: pointer;
  margin-left: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.normal-button:hover {
  background-color: #e4e7eb;
}

.normal-button svg {
  margin-right: 8px;
}
.menu-item-list .menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 12px 0 12px;
  color: #fff;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.menu-item-list .menu-item.selected {
  background-color: #3561bb;
  border-radius: 6px;
}

.menu-item .menu-item-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.data-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 150px);
}

.data-chat-container.data-chat-mode {
  justify-content: unset;
}

.data-chat-container .user-message {
  background-color: #f3f4f6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
  padding: 16px 10px;
  max-width: 750px;
  width: 100%;
}

.data-chat-container > .user-message {
  margin-top: 100px;
}
.data-chat-container > .user-message ~ .user-message {
  margin-top: 0;
}

.data-chat-container .bot-message-section {
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #4b5563;
  padding: 16px 10px;
  max-width: 750px;
  width: 100%;
}

.main-search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 550px;
  width: 90vw;
  height: 56px;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 0px 4px 0px 8px;
  box-sizing: border-box;
  color: #4b5563;
  margin-bottom: 8px;
  font-size: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
}
.search-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-backdrop.data-chat-mode {
  margin-top: auto;
  background-color: #fff;
  position: absolute;
  padding-bottom: 50px;
  bottom: 0;
  right: 0;
  left: 220px;
}

.main-search-bar-icon {
  font-size: 20px;
  margin-right: auto;
  z-index: 2;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.bot-message-section .suggestion-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: min-content;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #ebf2fe;
  margin-left: 12px;
  color: #173c81;
  font-weight: 400;
  font-size: 14px;
}

.bot-message-section .suggestion-button:hover {
  background-color: #d7e4ff;
}

.bot-message-section .suggestion-button:first-child {
  margin-left: 0;
}

.bot-message-section.inline-code-section {
  font-family: monospace;
}

.recommendations-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.recommendation-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  width: 304px;
  height: 84px;
  background: #fff4c2;
  border-radius: 10px;
  margin: 8px;
  cursor: pointer;
}

.recommendation-card .rec-text {
  margin-right: 12px;
}
.recommendation-card .arrow-icon {
  margin-left: auto;
  flex-shrink: 0;
}
.recommendation-card:hover {
  background: #e9c93b;
}

.bot-message-section.code-section {
  background-color: #eff7ff;
  color: #173c81;
  white-space: pre-wrap;
  margin-top: 16px;
  transition: height 0.1s ease-in-out;
  height: 20px;
}
.bot-message-section.code-section.open {
  height: 100%;
}

.code-section-icon {
  margin-left: auto;
  height: 100%;
}

.code-section-icon svg {
  transition: transform 0.1s ease-in-out;
}

.bot-message-section.code-section.open .code-section-icon svg {
  transform: rotate(180deg);
}

.bot-message-section.suggestion-section {
  padding: 10px;
}

.bot-message-section.data-vis-card {
  margin-top: 16px;
  background: #ffffff;
  /* gray/200 */

  border: 1px solid #e5e7eb;
  /* shadow-md */

  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 10px 10px;
}

.bot-message-section.table-section {
  padding: 0;
  max-width: 770px;
}

.main-search-bar-icon path {
  fill: #9aa0a5;
}

.main-search-bar {
  flex-grow: 1;
  border: none;
  outline: none;
  resize: none;
  font-family: "Sen", sans-serif;
  height: 20px;
  font-size: 16px;
  overflow: hidden;
}

.main-search-bar:focus {
  outline: none;
}

/* antd overrides */

.ant-table-wrapper {
  flex: 1;
  max-height: 500px;
  overflow: scroll;
  border-radius: 8px;
  width: 100%;
}

/* loading spinner anitmation */

.loading-animation {
  height: 64px;
  width: 64px;
  margin: 64px auto;
  position: relative;
}
.double-bounce1,
.double-bounce2 {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: #467ded;
  opacity: 0.6;
  position: absolute;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* helper classes */
.disable {
  opacity: 0.3;
  pointer-events: none;
}
.divider {
  background-color: #e4e4e4;
  height: 0.5px;
  width: 75%;
  margin: 24px auto;
}

.toggle-code-button {
  margin-top: 16px; /* Adjust the value according to your design */
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.toggle-code-button:hover {
  background-color: #0056b3;
  color: white;
}
